import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "en-demo",
  "seo": {
    "title": "-",
    "descr": "-"
  },
  "title": "Book a demo",
  "text": "Book a demo 45 minutes and we will carry it out via Team's web meeting or physical meeting where we give a presentation of Zimply and go through your challenges, needs and questions. Enter the desired time and date and we will return with a calendar invitation as soon as we can.",
  "url": "https://calendly.com/zimply/demo-with-zimply"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      